import { weightMeasureEnum } from "src/fixtures";
import { Product } from "src/redux/apiTypes";
import { EanAmountMap } from "src/utils/cart/createEanAmountMap";
import { BY_PIECE, BY_WEIGHT, addToCartButtonsEnum } from "src/utils/marketing/enhancedEcommerce";
import { getPriceStringByUnit } from "src/utils/product/formatPrice";

export const sendGoToCartButtonClick = () => {
  window.dataLayer.push({
    event: "go_to_cart_button_click",
    place: addToCartButtonsEnum.RECOMMENDED_CART,
  });
};

export const sendAddRecommendedProductsToCart = (products: Product[], eanAmountMap: EanAmountMap) => {
  const items = getItemsFromProducts(products, eanAmountMap);
  const sum = items.reduce((acc, item) => acc + Number(item.price), 0).toFixed(2);

  window.dataLayer.push({
    event: products.length > 1 ? "recommended_items_added_to_cart" : "recommended_item_selected",
    currency: "UAH",
    value: sum,
    place: addToCartButtonsEnum.RECOMMENDED_CART,
    items,
  });
};

export const getItemsFromProducts = (products: Product[], eanAmountMap: EanAmountMap) => {
  return products.map(p => ({
    item_id: p.ean,
    item_name: p.slug,
    item_brand: p.producer?.trademark || 'null',
    item_category: p.category_id,
    item_variant: p.unit === weightMeasureEnum.kg ? BY_WEIGHT : BY_PIECE,
    price: getPriceStringByUnit(p.price, p.unit),
    quantity: eanAmountMap[p.ean].amount,
  }));
};



import { Dispatch } from 'redux';
import { ProductWithAmount } from 'src/redux/modals/types';
import { sendAddToCartEvent, sendAddingProductsToCart, sendStatusCart } from 'src/utils/marketing/enhancedEcommerce';
import { RootState } from '../../../reducers';
import addProductsToClientCart from '../../clientCart/operations/addProductsToClientCart';
import addProductsToServerCart from '../../serverCart/operations/addProductsToServerCart';
import { CartItem } from '../cartTypes';
import { EanAmountMap } from 'src/utils/cart/createEanAmountMap';

const addProductsToCart = (
  products: ProductWithAmount[],
  eanAmountMap: EanAmountMap,
  place: string,
) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    app: { cartType },
    initialAppConfig: { currency },
  } = state;

  let updatedCartItems: CartItem[];

  if (cartType === null) {
    throw new Error('Using cart functions when cartType is null');
  }

  if (cartType === 'client') {
    dispatch(addProductsToClientCart(products, eanAmountMap));
    updatedCartItems = getState().cart.cart;
  } else {
    dispatch(addProductsToServerCart(products, eanAmountMap));
    updatedCartItems = getState().serverCart.data.items;
  }

  sendAddingProductsToCart(products, { eanAmountMap, place, currency });
  sendAddToCartEvent({ products, currency, place });
  sendStatusCart(updatedCartItems);
};

export default addProductsToCart;

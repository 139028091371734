import { ProductUnit, Quantity } from "src/redux/apiTypes";
import { getInitialAmount } from "./getInitialAmount";

interface AmountItem {
  amount: number;
  unit?: ProductUnit;
}

export interface EanAmountMap {
  [ean: string]: AmountItem;
}

interface BaseItemForMap {
  ean: string;
  unit: ProductUnit;
  quantity?: Quantity;
  amount?: number;
}

export const createEanAmountMap = <T extends BaseItemForMap>(items: T[]): EanAmountMap => {
  return items.reduce((map, { ean, unit, quantity, amount }) => {
    map[ean] = {
      amount: amount || getInitialAmount(unit, quantity, ean),
      unit,
    };
    return map;
  }, {});
};
